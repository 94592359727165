.icon-block {
    width: 100%;
    background: #617d84;
    box-sizing: border-box;
    padding-top: 85px;
    padding-bottom: 40px;
}

.icon-block-content {
    display: flex;
    flex-wrap: wrap;

    .icon-block-item {
        
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;

        box-sizing: border-box;

        .item-title {
            padding-top: 25px;
            color: $color-white;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
        }

        .item-icon {
            svg {
                width: 60px;
                height: 60px;
            }
        }


        &:first-child {
            .item-title {
                width: 70px;
            }
        }

        &:nth-child(2) {
            padding-right: 20px;
            
            .item-title {
                width: 150px;
            }
        }

        &:last-child {
            padding-right: 20px;

            .item-title {
                width: 100px;
            }
        }
    }
}

@media (min-width: $screen__l) {

    .icon-block {
        padding-bottom: 80px;
    }

    .icon-block-content {
    
        .icon-block-item {
            width: 25%;
            padding-bottom: 0;
            

            .item-title {
                padding-top: 40px;
                font-size: 20px;
            }

            .item-icon {
                svg {
                    width: 100px;
                    height: 100px;
                }
            }

            &:first-child {
                .item-title {
                    width: 100px;
                }
            }
    
            &:nth-child(2) {
                .item-title {
                    width: 220px;
                }
            }
    
            &:last-child {
                .item-title {
                    width: 150px;
                }
            }
        }
    }
}
